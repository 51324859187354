import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from "./components/about/about.component";
import {HomeComponent} from "./components/home/home.component";
import {ContactComponent} from "./components/contact/contact.component";
import {TermsComponent} from "./components/terms/terms.component";

const routes: Routes = [

    { path:'' , component: HomeComponent},
    { path:'about' , component: AboutComponent},
    { path:'contact' , component: ContactComponent},
    { path:'terms' , component: TermsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
